
import { defineComponent } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "kt-horizontal-wizard",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      employee: {
        name: "",
        designation: "",
        dob: "",
        mother_name: "",
        father_name: "",
        nid: "",
        passport: "",
        driving_license: "",
        mobile: "",
        email: "",
        gender: "",
        religion: "",
        present_address: "",
        present_district: "",
        permanent_address: "",
        permanent_district: "",
      },
      user: {
        password: "",
        password_confirmation: "",
        active_status: "1",
      },
      entityTypes: [],
      industry: [],
      entityInfos: [],
      divisions: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],
      loading: false,
    };
  },
  async created() {
    await this.getDistrictForEmp();
  },
  methods: {
    async formSubmit() {
      //let data = new FormData();

      let data = {
        employee: this.employee,
        user: this.user,
      };
      this.loading = true;
      await ApiService.post("configurations/employee/save", data)
        .then((response) => {
          this.loading = false;
          if (response.status == 200) {
            Swal.fire({
              title:"Success!",
              text: response.data.data,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              this.employee = {
                name: "",
                designation: "",
                dob: "",
                nid: "",
                mother_name: "",
                father_name: "",
                passport: "",
                driving_license: "",
                mobile: "",
                email: "",
                gender: "",
                religion: "",
                present_address: "",
                present_district: "",
                permanent_address: "",
                permanent_district: "",
              };
              this.user = {
                password: "",
                password_confirmation: "",
                active_status: "1",
              };
            });
          } else {
            let err = "";
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + "<br>";
            }
            Swal.fire({
              title: "Please check all the required field",
              html: err,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Close",
              customClass: {
                confirmButton: "btn btn-danger",
              },
            });
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          Swal.fire({
            title: "Unknown error",
            html: response.data.error,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Close",
            customClass: {
              confirmButton: "btn btn-danger",
            },
          });
          console.log(response);
        });
    },
  
    async getDistrictForEmp() {
      await ApiService.get("configurations/geo_district/list")
        .then((response) => {
          this.empDistricts = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
  setup() {
    const AssociationSchema = Yup.object().shape({

      employeeEmail: Yup.string()
        .email("Must be valid email")
        .required()
        .label("Email"),
      //presentDistrict: Yup.string().required().label("Present District"),
      name: Yup.string().required().label("Name"),
      designation: Yup.string().required().label("Designation"),
      gender: Yup.string().required().label("Gender"),
      mobile: Yup.number().required().min(11).label("Mobile"),
      dob: Yup.string().required().label("Date of Birth"),

      password: Yup.string()
        .required()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        )
        .label("Password"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Password didn't match!")
        .label("Confirm Password"),
    });
    return {
      AssociationSchema,
    };
  },
});
